import { useCallback, useState, useEffect, RefObject } from 'react';

/**
 * This hook handles setting the position of an HTML element to
 * match the position of a selected tab. Combined with CSS transitions,
 * this accomplishes an animated background that slides to sit behind
 * the selected tab.
 *
 * @param {RefObject<HTMLElement>} parentRef  A ref pointing to a parent
 * of the indicator, to store the dimensions and position of the indicator
 * @param {RefObject<HTMLElement>[]} childRef A ref pointing to the child element of
 * which to calculate the indicator offset and size
 * @param {number[]} dependencies  additional dependencies that should cause the indicator
 * offset and size to be recalculated
 * @returns {boolean} indicatorHasPosition True if the indicator has been given an
 * initial position corresponding to the selected tab.
 */
export const useIndicator = (
  parentRef: RefObject<HTMLElement>,
  childRef: RefObject<HTMLElement>,
  dependencies: number[] = []
): boolean => {
  const [indicatorHasPosition, setIndicatorHasPosition] = useState<boolean>(false);

  const updateIndicator = useCallback(() => {
    if (parentRef?.current && childRef?.current) {
      parentRef.current.style.setProperty(
        '--indicator-size',
        `${childRef.current?.offsetWidth ?? 0}px`
      );
      parentRef.current.style.setProperty(
        '--indicator-offset',
        `${childRef.current?.offsetLeft ?? 0}px`
      );
    }

    setIndicatorHasPosition(Boolean(parentRef?.current && childRef?.current));
  }, [parentRef, childRef]);

  // Update the indicator when the selected tab changes
  // Also update for changes to the tabRefs or parentRef, since
  // the updateIndicator function will change
  useEffect(updateIndicator, [parentRef, childRef, updateIndicator, ...dependencies]);

  // Update the indicator for any resizes and when fonts are done loading
  useEffect(() => {
    document.fonts?.ready.then(updateIndicator);
    window.addEventListener('resize', updateIndicator);

    const observer = new MutationObserver(updateIndicator);
    if (parentRef.current) {
      observer.observe(parentRef.current, {
        subtree: true,
        childList: true,
        characterData: true,
      });
    }

    return () => {
      window.removeEventListener('resize', updateIndicator);
      observer.disconnect();
    };
  }, [updateIndicator, parentRef]);

  return indicatorHasPosition;
};
