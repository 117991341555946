import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react';

export const StyledContainer = styled.div`
  z-index: 100;
  background-color: ${(props) => props.theme.colors.ui.primaryInverse};
  color: ${(props) => props.theme.colors.text};
  padding-block-start: var(${(props) => props.theme.responsive.spacing.xxxl});
  padding-block-end: var(${(props) => props.theme.responsive.spacing.xxxl});
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  opacity: 1;
  transition: opacity 0.3s ${(props) => props.theme.easing};
  will-change: opacity;

  &.hidden {
    opacity: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    padding-inline-start: 24px;
    padding-inline-end: 24px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    position: sticky;
    top: 0;
    padding: 0;
    padding-inline-start: 40px;
    padding-inline-end: 40px;

    &.hidden {
      opacity: 1;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding-inline-start: 60px;
    padding-inline-end: 60px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    padding-inline-start: 96px;
    padding-inline-end: 96px;
  }
`;

export const StyledHeadline = styled(Text)`
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: none;
  }
`;

export const StyledNav = styled.nav`
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    overflow-x: auto;
    padding-block-start: var(${(props) => props.theme.responsive.spacing.l});
    padding-block-end: var(${(props) => props.theme.responsive.spacing.l});
  }
`;

export const StyledList = styled.ul`
  margin: 0px;
  padding: 0px;
  padding-block-start: var(${(props) => props.theme.responsive.spacing.l});
  list-style: outside none none;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding-block-start: 0;
    display: flex;
    --indicator-size: 0px;
    --indicator-offset: 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-row: 1 / 4;
    grid-column: 1;
    justify-content: stretch;
  }
`;

export const StyledListItem = styled.li`
  &:nth-child(1n + 2) {
    padding-block-start: var(${(props) => props.theme.responsive.spacing.s});
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    white-space: nowrap;

    &:nth-child(1n + 2) {
      padding-block-start: 0;
      padding-inline-start: var(${(props) => props.theme.responsive.spacing.xl});
    }
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
`;

export const StyledLineIndicator = styled.div`
  position: relative;
  grid-row: 3 / 4;
  grid-column: 1;
  display: none;
  &::after {
    position: absolute;
    display: block;
    width: var(--indicator-size);
    will-change: transform;
    transform-origin: left;
    transform: translateX(var(--indicator-offset)) translateZ(0);
    height: 2px;
    margin-top: var(${(props) => props.theme.responsive.spacing.xxs});
    background-color: ${(props) => props.theme.colors.ui.primary};
    opacity: 1;
    transition: transform 130ms ${(props) => props.theme.easing};
    content: '';
    @media (prefers-reduced-motion) {
      transition: none;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: block;
  }
`;
