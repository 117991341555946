import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { useContext } from 'react';
import { I18nContext } from './i18nContext';

const i18nScope = 'fa.oneaudi-feature-app-anchor-navigation';

export type I18nMessageKey = 'headline';

export type I18nMessages = Record<I18nMessageKey, string>;

export interface I18nTexts {
  readonly headline: string;
}

export function useI18n(key: I18nMessageKey): string {
  const { i18nMessages } = useContext(I18nContext);

  return i18nMessages[key] ?? `__${i18nScope}.${key}__`;
}

export async function fetchI18nMessages(
  localeService: GfaLocaleServiceV1,
  i18nService: I18NServiceV1
): Promise<I18nMessages> {
  return new Promise((resolve, reject) => {
    try {
      i18nService.setScopes([i18nScope]);
      i18nService.registerCallback((messages) => {
        const res = Object.entries(messages[localeService.language]).reduce(
          (acc: Record<string, string>, [key, value]) => {
            // TODO: remove this as soon as i18nService is CMS agnostic (keys don't required
            // FA prefix/scope) messages are prefixed with the i18nScope (feature app name:
            // fa.cct-teaser). This is required due to how AEM handles translations. In order
            // to avoid verbosity in the source code the scope is removed from the keys to make
            // them match the required I18nMessageKey.
            // This way translations can be used like `useI18n('headline')` instead of `
            // useI18n('fa.cct-teaser.headline')`
            if (key.includes(i18nScope)) {
              const scopedKey = key.replace(`${i18nScope}.`, '');

              acc[scopedKey] = value;
            }

            return acc;
          },
          {}
        ) as I18nMessages;

        resolve(res);
      });
    } catch (err) {
      reject(err);
    }
  });
}
