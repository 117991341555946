export const useDebounce = (
  callback: (args: Event) => void,
  time: number
): EventListenerOrEventListenerObject => {
  let interval: NodeJS.Timeout | null;
  return (...args: [evt: Event]) => {
    if (interval) clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };
};
