import * as React from 'react';
import type { Logger } from '@feature-hub/logger';
import { RenderMode } from '@volkswagen-onehub/audi-render-mode-service';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { useContext } from 'react';
import { Dependencies } from '../FeatureHubAppDefinition';

export interface FeatureAppContextState {
  readonly logger?: Logger;
  readonly renderMode: RenderMode;
  readonly trackingService: TrackingServiceV2 | undefined;
  readonly localeService: GfaLocaleServiceV1;
  readonly i18nService: I18NServiceV1;
}

export const FeatureAppContext = React.createContext<FeatureAppContextState>(
  {} as FeatureAppContextState
);

export interface FeatureAppContextProviderProps {
  readonly featureServices: Dependencies;
  readonly children: JSX.Element;
}

export function FeatureAppContextProvider({
  featureServices,
  children,
}: React.PropsWithChildren<FeatureAppContextProviderProps>): JSX.Element {
  const {
    's2:logger': logger,
    'gfa:locale-service': localeService,
    'dbad:audi-i18n-service': i18nService,
    'audi-tracking-service': trackingService,
    'audi-render-mode-service': renderModeService,
  } = featureServices;
  return (
    <FeatureAppContext.Provider
      value={{
        logger,
        renderMode: renderModeService?.getRenderMode() || RenderMode.DEFAULT,
        trackingService,
        i18nService,
        localeService,
      }}
    >
      {children}
    </FeatureAppContext.Provider>
  );
}

function getContext() {
  const context = React.useContext(FeatureAppContext);
  if (context === null) {
    throw Error('Did you forget to wrap your Feature App in <FeatureAppContextProvider>?');
  }
  return context;
}

export const useLogger = (): Logger | undefined => useContext(FeatureAppContext).logger;

export function useRenderMode(): RenderMode {
  return getContext().renderMode;
}
