import * as React from 'react';
import { useContent } from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';
import { ThemeProvider } from 'styled-components';
import { audiDarkTheme, audiLightTheme } from '@audi/audi-ui-react';

import { useContext, useEffect } from 'react';
import pkg from '../../package.json';
import { AnchorNavigation } from './components/anchornavigation/AnchorNavigation';
import { AsyncStateHolder, createInitialState, State } from './FeatureHubAppDefinition';
import { I18nContextProvider } from './i18n/i18nContext';
import { useI18n } from './i18n';
import { Content, THEME } from '../types';
import { FeatureAppContext, useLogger } from './context/FeatureAppContext';
import { useTrackingManager } from './utils/useTrackingManager';

interface Props {
  readonly asyncStateHolder: AsyncStateHolder;
}

const AnchorNavigationFeatureApp: React.FC = () => {
  const trackingManager = useTrackingManager();

  const i18nMessages = { headline: useI18n('headline') };

  React.useEffect(() => {
    trackingManager.ready(pkg.version);
  }, []);

  const linkTracking = React.useCallback((label: string, anchorIndex: number): void => {
    trackingManager.click(label, anchorIndex);
  }, []);

  const navigationTracking = React.useCallback((isOpen: boolean): void => {
    trackingManager.navigation(isOpen);
  }, []);

  return (
    <AnchorNavigation
      {...i18nMessages}
      linkTracking={linkTracking}
      navigationTracking={navigationTracking}
    />
  );
};

const AsyncFeatureApp: React.FC<Props> = ({ asyncStateHolder }: Props) => {
  const content = useContent<Content>();

  if (!content) return null;

  const logger = useLogger();
  const theme = content.lightDarkMode === THEME.DARK ? audiDarkTheme : audiLightTheme;
  const [state, setState] = React.useState<State | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined
  );

  const { localeService, i18nService } = useContext(FeatureAppContext);

  useEffect(() => {
    let mounted = true;
    if (state && content) {
      createInitialState(localeService, i18nService).then((state) => {
        mounted && setState(state);
      });
    }

    return () => {
      mounted = false;
    };
  }, [content]);

  useEffect(() => {
    // when asyncStateHolder is a function it means the state could not be
    // properly serialized by the server and it is not available on the client. In that case
    // this effect will try to fetch the state as soon as the component is mounted on the client.
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      asyncStateHolder().then((state) => {
        setState(state);
      });
    } else {
      logger?.info('SSR serialized state: ', asyncStateHolder);
    }
  }, []);

  if (!state) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <I18nContextProvider i18nMessages={state.i18nMessages}>
        <AnchorNavigationFeatureApp />
      </I18nContextProvider>
    </ThemeProvider>
  );
};

export default AsyncFeatureApp;
